import axios from 'axios';

import ENV from '@/env';

import { log, trace, warn } from './logger';
import { detectIE } from './util';

const MAX_RESPONSE_LOG_SIZE = 100 * 1024;

export const getApiEndpoint = url => ENV.api.base_url + url;

export const getHeaders = (method, onlyAuth = false) => {
	const headers = {};
	if (onlyAuth) {
		return headers;
	}
	if (method === 'post' || method === 'put' || method === 'patch') {
		headers['Content-Type'] = 'application/json';
	}

	// Deal with IE aggressive caching
	// http://stackoverflow.com/questions/2848945/prevent-ie-caching
	if (detectIE() && method === 'get') {
		headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
		headers.Pragma = 'no-cache';
		headers.Expires = '0';
	}
	return headers;
};

export const request = opts => {
	let stack;
	if (ENV.dev && ENV.api.trace) {
		try {
			throw new Error();
		} catch (err) {
			stack = err.stack.split('\n');
			stack.shift();
			stack = stack.join('\n');
		}
	}

	if (!opts.url) {
		throw new Error('url is required');
	}

	opts.baseURL = ENV.api.base_url;
	opts.method = opts.method || 'get';
	opts.withCredentials = true;

	const headers = opts.headers || {};
	opts.headers = getHeaders(opts.method);
	if (headers) {
		opts.headers = {
			...opts.headers,
			...headers,
		};
	}
	const logMethod = ENV.api.trace ? trace : log;
	const logData = ENV.api.trace
		? { ...(opts.data || { data: opts.data }), stack }
		: { ...(opts.data || { data: opts.data }) };
	logMethod(
		'api',
		`${opts.method.toUpperCase()} ${opts.url} ${
			opts.params ? JSON.stringify(opts.params) : ''
		} ==>`,
		logData,
	);

	return axios(opts)
		.then(res => {
			const { config, data, request, ...rest } = res;
			const logRes = { ...rest, data, stack: null };
			if (ENV.api.trace) {
				logRes.stack = stack;
			}
			if (request.responseText?.length > MAX_RESPONSE_LOG_SIZE) {
				logRes.data = `<Response too large, check the network log>`;
			}
			logMethod('api', `${opts.method.toUpperCase()} ${opts.url} <==`, logRes);
			return res.data;
		})
		.catch(res => {
			/** @type {Error & {status?: number, _logged?: boolean}} */
			let err = null;
			const { response } = res;
			if (response && response.data && response.data.error) {
				err = response.data.error;
			} else if (response) {
				err = new Error(response.statusText);
				err.status = response.status;
			} else {
				err = new Error(res.message || 'HTTP Error');
				err.status = 0;
			}
			err.stack = stack || err.stack;
			warn('api', `${opts.method.toUpperCase()} ${opts.url}`, err, res);
			err._logged = true;

			throw err;
		});
};

export const get = (url, params, headers) => request({ headers, params, url });

export const post = (url, params, data, headers) =>
	request({ data, headers, method: 'post', params, url });

export const patch = (url, params, data, headers) =>
	request({ data, headers, method: 'patch', params, url });

export const put = (url, params, data, headers) =>
	request({ data, headers, method: 'put', params, url });

export const del = (url, params, data, headers) =>
	request({ data, headers, method: 'delete', params, url });

export const head = (url, params, headers) => request({ headers, method: 'head', params, url });
