import { LatLng, latLng } from 'leaflet';
import isEqual from 'lodash/isEqual';

import ENV from '@/env';
import { makeImageQueryString } from '@/lib/api';
import { assign } from '@/lib/util';

import { Criteria as BaseCriteria } from './data';
import FA from './font_awesome';

const { visuals } = ENV;
const {
	max_lightbox_width,
	preview_image_max_height,
	preview_image_max_width,
	site_thumbnail_height_small,
	site_thumbnail_width_small,
} = visuals;

export const THUMB_QUERY_STRING = makeImageQueryString(
	site_thumbnail_width_small,
	site_thumbnail_height_small,
	true,
);

export const SECTIONS = {
	360: '360',
	add_asset_data: 'add_asset_data',
	avv: 'avv',
	dashboard: 'dashboard',
	drone_image: 'drone_image',
	floorplan: 'floorplan',
	model_3d: '3d',
	orthophoto: 'orthophoto',
	pointcloud: 'pointcloud',
	tour: 'tour',
	viewer: 'viewer',
};

/** @typedef {import('./sites').Site} Site */

/**
 * @typedef {Object} IntervalLiteral
 * @property {string|number|Date} date
 * @property {T} data
 * @template T
 */

/** @typedef {keyof typeof PROJECTIONS} Projection */
/** @typedef {{[x in Projection]: x}} PROJECTIONS */

export const PROJECTIONS = {
	mode_2d: 'mode_2d',
	mode_3d: 'mode_3d',
	mode_360: 'mode_360',
};

export const ASSET_VARIANTS = {
	'360_tagging': '360_tagging',
	bim: 'bim',
	drone_image: 'drone_image',
	floorplan: 'floorplan',
	model_3d: 'model_3d',
	orthophoto: 'orthophoto',
	pointcloud: 'pointcloud',
	tour: 'tour',
};

export const TAGGING_MODES = {
	edit: 'edit',
	new: 'new',
	preview: 'preview',
};

export const TAGGING_SECTIONS = {
	[SECTIONS.orthophoto]: 'orthophoto',
	[SECTIONS.model_3d]: 'model_3d',
};

export const ASSET_VARIANTS_TITLES = {
	[ASSET_VARIANTS.orthophoto]: 'Orthophoto',
	[ASSET_VARIANTS.tour]: 'Tour',
	[ASSET_VARIANTS['360_tagging']]: '360',
	[ASSET_VARIANTS.model_3d]: '3D Model',
	[ASSET_VARIANTS.pointcloud]: 'Point Cloud',
	[ASSET_VARIANTS.bim]: 'BIM',
	[ASSET_VARIANTS.floorplan]: 'Floorplan',
	[ASSET_VARIANTS.drone_image]: 'Aerial',
};

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const COLOR_CODES = {
	blue: '#2D9CDB',
	red: '#F41818',
	orange: '#F48420',
	green: '#01D15A',
	yelow: '#F5D423',
	purple: '#9B51E0',
};

export const COLOR_CODES_VALUES = Object.values(COLOR_CODES);

export const DEFAULT_NEW_TAG_COLOR = COLOR_CODES.blue;

export const AVV_ASSET_VARIANTS = [
	ASSET_VARIANTS.bim,
	ASSET_VARIANTS.model_3d,
	ASSET_VARIANTS.pointcloud,
];

export const ASSET_VARIANT_ORDER_PRIORITY = [
	ASSET_VARIANTS.tour,
	ASSET_VARIANTS.floorplan,
	ASSET_VARIANTS.orthophoto,
	ASSET_VARIANTS.drone_image,
	ASSET_VARIANTS.model_3d,
	ASSET_VARIANTS.pointcloud,
];

export const VIEWER_SIDEPANEL_THUMBNAIL_SIZE = {
	height: 150,
	width: 227,
};

/** @typedef {keyof typeof VIEWER_TOOLS} ViewerTool */
/** @typedef {{[x in ViewerTool]: x}} VIEWER_TOOLS */

export const VIEWER_TOOLS = {
	area: 'area',
	distance: 'distance',
	height: 'height',
	polyline: 'polyline',
};

export const VIEWER_TOOL_TITLES = {
	[VIEWER_TOOLS.distance]: 'Distance',
	[VIEWER_TOOLS.componentDistance]: 'Component Distance',
	[VIEWER_TOOLS.polyline]: 'Polyline Distance',
	[VIEWER_TOOLS.horizontal]: 'Horizontal distance',
	[VIEWER_TOOLS.vertical]: 'Vertical distance',
	[VIEWER_TOOLS.height]: 'Height from terrain',
	[VIEWER_TOOLS.area]: 'Area',
	[VIEWER_TOOLS.point]: 'Point coordinates',
};

export const FP_MODE = {
	firstPerson: 'firstPerson',
};

export const FP_MODE_ICONS = {
	[FP_MODE.firstPerson]: FA.person_o,
};

/** @typedef {keyof typeof FLOOR_PLAN_MODES} Floor_plan_mode */
/** @typedef {{[x in Floor_plan_mode]: x}} FLOOR_PLAN_MODES */

export const FLOOR_PLAN_MODES = {
	floorPlan: 'floorPlan',
};

export const FLOOR_PLAN_MODE_ICONS = {
	[FLOOR_PLAN_MODES.floorPlan]: FA.cubes,
};

export const TABS = {
	360: '360',
	agority: 'agority',
	documents: 'documents',
	planner: 'planner',
	reports: 'reports',
	tags: 'tags',
	viewer: 'viewer',
};

export const BOTTOM_TABS = {
	legacy_platform: 'legacy_platform',
	support: 'support',
};

const _WIDGETS = {
	chart: 'chart',
	heatmap_legend: 'heatmap_legend',
	map: 'map',
	sidebar_left: 'sidebar_left',
	sidebar_right: 'sidebar_right',
};

export const WIDGETS = /** @type {{[k in Widget]: k}} */ (_WIDGETS);

/** @typedef {keyof typeof _WIDGETS} Widget */
/** @typedef {{[k in Widget]: boolean}} Widgets */

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const VIEWER_TABS = {
	info: 'info',
	environment: 'environment',
	documents: 'documents',
	thumbnail_list: 'thumbnail_list',
	drone_images: 'drone_images',
	tag_list: 'tag_list',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const VIEWER_TABS_ICONS = {
	[VIEWER_TABS.info]: FA.info,
	[VIEWER_TABS.environment]: FA.seedling,
	[VIEWER_TABS.documents]: FA.files,
	[VIEWER_TABS.tag_list]: FA.tag,
	[VIEWER_TABS.thumbnail_list]: FA.street_view,
	[VIEWER_TABS.drone_images]: FA.drone,
};

export const VIEWER_INFO_SECTIONS = {
	alt: 'alt',
	created_at: 'created_at',
	lat: 'lat',
	lng: 'lng',
};

export const VIEWER_INFO_SECTIONS_TITLES = {
	[VIEWER_INFO_SECTIONS.alt]: 'Altitude',
	[VIEWER_INFO_SECTIONS.lat]: 'Latitude',
	[VIEWER_INFO_SECTIONS.lng]: 'Longitude',
	[VIEWER_INFO_SECTIONS.created_at]: 'Creation Date',
};

export const VISION_FLOORPLAN_SUBSTANCE = {
	acetic_acid: 'acetic_acid',
	acetone: 'acetone',
	alcohol: 'alcohol',
	butane: 'butane',
	carbon_dioxid: 'carbon_dioxid',
	carbon_disulfide: 'carbon_disulfide',
	carbon_monoxide: 'carbon_monoxide',
	ethanol: 'ethanol',
	formaldehyde: 'formaldehyde',
	humidity: 'humidity',
	methylene_chloride: 'methylene_chloride',
	moisture: 'moisture',
	nitrogen_oxide: 'nitrogen_oxide',
	oxygen: 'oxygen',
	ozone: 'ozone',
	sulfur_dioxide: 'sulfur_dioxide',
	temperature: 'temperature',
};

export const VISION_FLOORPLAN_SUBSTANCE_LABELS = {
	[VISION_FLOORPLAN_SUBSTANCE.acetic_acid]: 'Acetic acid',
	[VISION_FLOORPLAN_SUBSTANCE.acetone]: 'Acetone',
	[VISION_FLOORPLAN_SUBSTANCE.alcohol]: 'Alcohol',
	[VISION_FLOORPLAN_SUBSTANCE.butane]: 'Butane',
	[VISION_FLOORPLAN_SUBSTANCE.carbon_dioxid]: 'Carbon dioxide',
	[VISION_FLOORPLAN_SUBSTANCE.carbon_disulfide]: 'Carbon disulfide',
	[VISION_FLOORPLAN_SUBSTANCE.carbon_monoxide]: 'Carbon monoxide (CO)',
	[VISION_FLOORPLAN_SUBSTANCE.ethanol]: 'Ethanol',
	[VISION_FLOORPLAN_SUBSTANCE.formaldehyde]: 'Formaldehyde',
	[VISION_FLOORPLAN_SUBSTANCE.humidity]: 'Humidity (atmosphere)',
	[VISION_FLOORPLAN_SUBSTANCE.methylene_chloride]: 'Methylene chloride',
	[VISION_FLOORPLAN_SUBSTANCE.moisture]: 'Moisture (in wall)',
	[VISION_FLOORPLAN_SUBSTANCE.nitrogen_oxide]: 'Nitrogen oxide',
	[VISION_FLOORPLAN_SUBSTANCE.oxygen]: 'Oxygen (O2)',
	[VISION_FLOORPLAN_SUBSTANCE.ozone]: 'Ozone (O3)',
	[VISION_FLOORPLAN_SUBSTANCE.sulfur_dioxide]: 'Sulfur dioxide (SO2)',
	[VISION_FLOORPLAN_SUBSTANCE.temperature]: 'Temperature',
};

export const VISION_FLOORPLAN_TRACER_SUBSTANCE = {
	benzene: 'benzene',
	co: 'co',
	co2: 'co2',
	general_combustible_gas: 'general_combustible_gas',
	humidity: 'humidity',
	hydrogen: 'hydrogen',
	lux: 'lux',
	methane: 'methane',
	natural_gas: 'natural_gas',
	oxygen: 'oxygen',
	ozone: 'ozone',
	propane: 'propane',
	refrigerant: 'refrigerant',
	sound: 'sound',
	temperature: 'temperature',
	um03: 'um03',
	um05: 'um05',
	um2: 'um2',
	um10: 'um10',
	um25: 'um25',
	um50: 'um50',
	um100: 'um100',
	vocs_ammonia: 'vocs_ammonia',
};

export const VISION_FLOORPLAN_TRACER_SUBSTANCE_LABELS = {
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.general_combustible_gas]: 'General combustible gas (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.methane]: 'Methane (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.natural_gas]: 'Natural gas (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.propane]: 'Propane (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.co]: 'CO (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.hydrogen]: 'Hydrogen (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.vocs_ammonia]: 'Ammonia (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.refrigerant]: 'Refrigerant (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.co2]: 'CO2 (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um03]: 'um03 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um05]: 'um05 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um10]: 'um10 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um25]: 'um25 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um50]: 'um50 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um100]: 'um100 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.benzene]: 'Benzene (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.ozone]: 'Ozone (ppm)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.temperature]: 'Temperature (°C)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.humidity]: 'Humidity (%)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.lux]: 'Lux (lx)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.sound]: 'Sound (dB)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.um2]: 'um2.5 (µg/m3)',
	[VISION_FLOORPLAN_TRACER_SUBSTANCE.oxygen]: 'Oxygen (µg/m3)',
};

export const VISION_TYPE = {
	property: 'property',
	rail: 'rail',
};

export const VISION_TYPE_ICONS = {
	[VISION_TYPE.property]: FA.building,
	[VISION_TYPE.rail]: FA.train,
};

export const VISION_PROPERTY_MARKER_TOOLTIP = {
	building: 'building',
};
export const VISION_PROPERTY_MARKER_TOOLTIP_LABEL = {
	[VISION_PROPERTY_MARKER_TOOLTIP.building]: 'Name',
	[VISION_PROPERTY_MARKER_TOOLTIP.address]: 'Address',
	[VISION_PROPERTY_MARKER_TOOLTIP.total_space]: 'Total space',
	[VISION_PROPERTY_MARKER_TOOLTIP.scanned_on]: 'Scan date',
};

export const VISION_RAIL_STATION_TYPE = {
	cave: 'cave',
	covered_tunnel: 'covered_tunnel',
	maintenance: 'maintenance',
	outside: 'outside',
};

export const VISION_RAIL_STATION_TYPE_LABEL = {
	[VISION_RAIL_STATION_TYPE.cave]: 'Cave',
	[VISION_RAIL_STATION_TYPE.covered_tunnel]: 'Covered tunnel',
	[VISION_RAIL_STATION_TYPE.outside]: 'Outside',
	[VISION_RAIL_STATION_TYPE.maintenance]: 'Maintenance depot',
};

export const VISION_RAIL_STATION_TUBE_TYPE = {
	blue: 'blue',
	green: 'green',
	purple: 'purple',
	red: 'red',
};

export const VISION_RAIL_STATION_TUBE_TYPE_LABEL = {
	[VISION_RAIL_STATION_TUBE_TYPE.red]: 'Red',
	[VISION_RAIL_STATION_TUBE_TYPE.green]: 'Green',
	[VISION_RAIL_STATION_TUBE_TYPE.blue]: 'Blue',
	[VISION_RAIL_STATION_TUBE_TYPE.purple]: 'Purple',
};

export const VISION_RAIL_MARKER_TOOLTIP = {
	entrances: 'entrances',
	// name: 'name',
	short_term: 'short_term',
	station_type: 'station_type',
	tube: 'tube',
};

export const VISION_RAIL_MARKER_TOOLTIP_LABEL = {
	// [VISION_RAIL_MARKER_TOOLTIP.name]: 'Name',
	[VISION_RAIL_MARKER_TOOLTIP.short_term]: 'Name',
	[VISION_RAIL_MARKER_TOOLTIP.entrances]: 'Amount of entrances',
	[VISION_RAIL_MARKER_TOOLTIP.station_type]: 'Station type',
	[VISION_RAIL_MARKER_TOOLTIP.tube]: 'Tube',
};

export const ION_ASSET_STATUS = {
	AWAITING_FILES: 'AWAITING_FILES',
	COMPLETE: 'COMPLETE',
	DATA_ERROR: 'DATA_ERROR',
	ERROR: 'ERROR',
	IN_PROGRESS: 'IN_PROGRESS',
	NOT_STARTED: 'NOT_STARTED',
};

//* *********************************************************************************************************************

export class Asset {
	constructor(source) {
		/** @type {number} */
		this.id = undefined;
		/** @type {number} */
		this.folder_id = undefined;
		/** @type {string} */
		this.name = undefined;
		/** @type {string} */
		this.type = undefined;
		/** @type {number} */
		this.lat = undefined;
		/** @type {number} */
		this.lng = undefined;
		/** @type {number} */
		this.alt = undefined;
		/** @type {{[K: string]: any}} */
		this.details = undefined;
		/** @type {AssetVariant} */
		this.current_variant = undefined;
		/** @type {AssetVariant[]} */
		this.variants = undefined;

		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = undefined;

		assign(this, this, source);
	}
}

export class AssetVariant {
	constructor(source) {
		/** @type {number} */
		this.id = undefined;
		/** @type {number} */
		this.asset_id = undefined;
		/** @type {number} */
		this.site_id = undefined;
		/** @type {number} */
		this.cesium_ion_asset_id = undefined;
		/** @type {keyof ION_ASSET_STATUS & string} */
		this.cesium_ion_asset_status = undefined;
		/** @type {Date} */
		this.scanned_on = undefined;
		/** @type {number} */
		this.lat = undefined;
		/** @type {number} */
		this.lng = undefined;
		/** @type {number} */
		this.alt = undefined;
		/** @type {{[K: string]: any}} */
		this.details = undefined;
		/** @type {{[K: string]: any}} */
		this.viewer_settings = undefined;
		/** @type {string} */
		this.url = undefined;
		/** @type {Promise<any>} */
		this.resource = undefined;
		/** @type {string} */
		this.name = undefined;
		/** @type {string} */
		this.type = undefined;
		/** @type {Site} */
		this.site = undefined;

		/** @type {Date} */ this.created_at = undefined;
		/** @type {Date} */ this.updated_at = undefined;
		/** @type {Date} */ this.deleted_at = undefined;

		assign(this, this, source);
	}
}

export class InventoryItemSummary {
	constructor(source) {
		/** @type {string} */ this.type = undefined; // 'Bench'
		/** @type {number} */ this.amount = undefined; // 2
		/** @type {string} */ this.category = undefined; // 'outdoor_furniture'
		/** @type {string} */ this.description = undefined; // null

		Object.assign(this, source);
	}
}

export class Criteria extends BaseCriteria {
	/** @param {any} [source] */
	constructor(source) {
		super();
		/** @type {import('leaflet').LatLngLiteral} */
		this.center = undefined;
		/** @type {import('leaflet').LatLngLiteral} */
		this.ne = undefined;
		/** @type {import('leaflet').LatLngLiteral} */
		this.sw = undefined;
		/** @type {number}} */
		this.max_zoom = undefined;
		/** @type {number}} */
		this.zoom = undefined;
		/** @type {string[]}} */
		this.cells = undefined;
		assign(this, this, source);
	}

	coerce() {
		super.coerce();
		const { center, max_zoom, ne, sw, zoom } = this;

		this.center = this.decodeLatLng(center);
		this.ne = this.decodeLatLng(ne);
		this.sw = this.decodeLatLng(sw);
		if (typeof zoom === 'string') {
			this.zoom = Number(zoom);
		}
		if (typeof max_zoom === 'string') {
			this.max_zoom = Number(max_zoom);
		}
		return this;
	}

	equals(criteria, ignoreKeys = null) {
		if (!criteria) {
			return false;
		}
		if (!ignoreKeys) {
			// ignoreKeys = ['cells', 'ne', 'sw', 'max_zoom'];
			ignoreKeys = ['cells', 'max_zoom'];
		}
		const keys = Object.keys(this);
		const key = keys.find(key => {
			if (ignoreKeys && (ignoreKeys[key] || (ignoreKeys.indexOf && ignoreKeys.indexOf(key) >= 0))) {
				return false;
			}

			const thisVal = this[key];
			const criteriaVal = criteria[key];
			if (thisVal && thisVal.getTime && criteriaVal && criteriaVal.getTime) {
				// Compare dates
				return thisVal.getTime() !== criteriaVal.getTime();
			}
			if (Array.isArray(thisVal)) {
				return !isEqual(thisVal, criteriaVal);
			}
			if (thisVal instanceof LatLng && criteriaVal instanceof LatLng) {
				return !thisVal.equals(criteriaVal, 1.0e-7);
			}
			if (typeof thisVal === 'object') {
				return !isEqual(thisVal, criteriaVal);
			}

			return thisVal !== criteriaVal;
		});

		// if (key) {
		// 	console.log('criteria changed:', key, this[key], criteria[key]); // eslint-disable-line no-console
		// }
		return !key;
	}

	serialize() {
		const serialized = super.serialize();
		serialized.center = this.encodeLatLng(this.center, 5);
		serialized.ne = this.encodeLatLng(this.ne, 5);
		serialized.sw = this.encodeLatLng(this.sw, 5);
		delete serialized.exclude;
		delete serialized.max_zoom;
		return serialized;
	}

	decodeLatLng(val) {
		if (val instanceof LatLng) {
			return val.clone();
		}
		if (typeof val === 'string' && val.length) {
			const [lat, lng] = String(val).split(',').map(Number);
			return latLng({ lat, lng });
		}
		return val ? latLng(val) : val;
	}

	encodeLatLng(val, digits = 7) {
		return [+val.lat.toFixed(digits), +val.lng.toFixed(digits)].join(',');
	}

	/**
	 * @param {{value?: number, offset?: number}} [fromPage]
	 * @param {boolean} [isForApi]
	 * @returns {{[x: string]: string|number}}
	 */
	toQuery(fromPage, isForApi = true) {
		/** @type {{[x: string]: string|number}} */
		const query = {};
		const { cells, center, max_zoom, ne, sw, zoom } = this;

		if (center) {
			query.center = this.encodeLatLng(center);
		}
		if (ne) {
			query.ne = this.encodeLatLng(ne);
		}
		if (sw) {
			query.sw = this.encodeLatLng(sw);
		}
		if (max_zoom) {
			query.max_zoom = String(max_zoom);
		}
		if (zoom) {
			query.zoom = String(zoom);
		}
		if (!isForApi) {
			return query;
		}
		if (cells) {
			query.exclude = String(cells);
		}
		return query;
	}
}

/**
 * @param {{url: string, width?: number, height?: number}} image
 * @param {{width?: number, height?: number, max?: boolean}} options
 */
export const createImageSource = (
	image,
	options = { height: undefined, max: true, width: undefined },
) => {
	const { url } = image;
	const { max } = options;
	const width =
		options.width === undefined ? Math.min(image.width, max_lightbox_width) : options.width;
	const height =
		options.height === undefined
			? Math.max(Math.min(image.height, preview_image_max_width), preview_image_max_height)
			: options.height;

	return {
		source: {
			fullscreen: url,
			regular: url + makeImageQueryString(width, height, max),
			thumbnail: url + THUMB_QUERY_STRING,
		},
	};
};
